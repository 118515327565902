<template>
  <div>
    <terminal/>
  </div>
</template>

<script>
import Terminal from './Terminal'

export default {
  name: 'PersonalProfile',
  components: { Terminal }
}
</script>

<style scoped>
</style>
