import { render, staticRenderFns } from "./HammayounSaficvEn.vue?vue&type=template&id=41064c86&scoped=true&"
import script from "./HammayounSaficvEn.vue?vue&type=script&lang=js&"
export * from "./HammayounSaficvEn.vue?vue&type=script&lang=js&"
import style0 from "./HammayounSaficvEn.vue?vue&type=style&index=0&id=41064c86&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41064c86",
  null
  
)

export default component.exports